import { yupResolver } from '@hookform/resolvers/yup';
import { Link, navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from '../auth';
import { Checkbox } from '../components/form/checkbox';
import { InputField } from '../components/form/input-field';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required(),
  acceptPrivacy: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      'Bitte lies die Hinweise zum Datenschutz und akzeptiere diese!'
    )
});

export default () => {
  const { register, handleSubmit, watch, errors, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { login, user } = useAuth();
  const [error, setError] = useState<string>(undefined);
  const onSubmit = useCallback(async data => {
    try {
      const result = await login(getValues().email, getValues().password);
      if (!result) {
        setError(
          'Hier scheint etwas nicht zu stimmen. Wahrscheinlich verwendest du ungültige Zugangsdaten.'
        );
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.log(typeof e);
    }
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/mein-fakeos');
    }
  }, [user]);

  return (
    <Layout>
      <Navigation fixed />
      <div className="section">
        <div className="container is-max-desktop">
          {user && (
            <div className="columns is-centered">
              <div className="column is-half">
                <h3 className="subtitle">fakeOS</h3>
                <h2 className="title">Login?</h2>
                <p>Du bist doch schon eingeloggt ...</p>
              </div>
            </div>
          )}
          {!user && (
            <div className="columns is-centered">
              <div className="column is-half">
                <h3 className="subtitle">fakeOS</h3>
                <h2 className="title">Login</h2>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputField
                    name="email"
                    label="E-Mail"
                    type="email"
                    error={errors.email?.message}
                    innerRef={register}
                  />

                  <InputField
                    name="password"
                    label="Passwort"
                    type="password"
                    error={errors.password?.message}
                    innerRef={register}
                  />

                  <Checkbox
                    innerRef={register}
                    name="acceptPrivacy"
                    error={errors.acceptPrivacy?.message}
                    label={
                      <>
                        Ich habe die{' '}
                        <Link to="/datenschutz">Hinweise zum Datenschutz</Link>{' '}
                        gelesen und akzeptiere diese.
                      </>
                    }
                  />

                  {error && (
                    <article className="message is-danger">
                      <div className="message-body">{error}</div>
                    </article>
                  )}
                  <div className="control">
                    <button type="submit" className="button is-link">
                      Anmelden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
