import React, { ComponentType, ReactNode } from 'react';

interface Props {
  label: ReactNode;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  error?: string;
  innerRef?: any;
  name?: string;
  disabled?: boolean;
  onFocus?: () => void;
}
export const Checkbox: ComponentType<Props> = ({
  label,
  checked,
  onChange,
  error,
  innerRef,
  name,
  onFocus,
  disabled
}) => {
  return (
    <div className="field">
      <div className="control">
        <label className="checkbox">
          <input
            onFocus={onFocus}
            disabled={disabled}
            ref={innerRef}
            name={name}
            checked={checked}
            onChange={event => {
              if (onChange) {
                onChange(!checked);
                //if (event.target.value === 'on') {
                //  onChange(true);
                //} else {
                //  onChange(false);
                //}
              }
            }}
            type="checkbox"
          />{' '}
          {label}
        </label>
        {!!error && (
          <>
            <span className="icon is-small is-right">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <p className="help is-danger">{error}</p>
          </>
        )}
      </div>
    </div>
  );
};
