import classNames from 'classnames';
import React, { ComponentType } from 'react';
interface Props {
  innerRef?: any;
  label: string;
  placeholder?: string;
  error?: string;
  value?: string;
  name?: string;
  onChange?: (value: string) => void;
  type?: string;
}
export const InputField: ComponentType<Props> = props => {
  const {
    innerRef,
    label,
    error,
    placeholder,
    value,
    onChange,
    name,
    type = 'text'
  } = props;
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control has-icons-left has-icons-right">
        <input
          ref={innerRef}
          className={classNames('input', { 'is-danger': !!error })}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
        />
        {type === 'email' && (
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        )}
        {type === 'password' && (
          <span className="icon is-small is-left">
            <i className="fas fa-key"></i>
          </span>
        )}
        {!!error && (
          <>
            <span className="icon is-small is-right">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <p className="help is-danger">{error}</p>
          </>
        )}
      </div>
    </div>
  );
};
